export const ColaboradoresData = [
    {
        name: "Bárbara Prajs",
        text: "Desarrolladora web"
    },
    {
        name: "Leandro Gómez",
        text: "MKT digital"
    },
    {
        name: "Laura Blaha",
        text: "Equipo contable"
    },
    {
        name: "Contanza Togni",
        text: "Universidad de Palermo"
    },
    {
        name: "Martín Rubiera",
        text: "Diseño editorial"
    },
    {
        name: "Juan Pablo Carrea",
        text: "Desarrollador web"
    },
    {
        name: "Lucía Licciardi",
        text: "MKT digital"
    },
    {
        name: "Nehuen Buriasco",
        text: "Diseño gráfico"
    }
]

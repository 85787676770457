import React from "react";
import { Link } from "react-router-dom";
import { NosotrosData } from "../data/IntegrantesData";
import { ColaboradoresData } from "../data/ColaboradoresData";

function Portfolio() {
  return (
    <div className="container">
      <section id="portfolio">
        <div>
          <div className="col-md-6">
            <hr />
            <h2 className="xbold black">
              Somos AG. Una pequeña gran agencia con grandes y pequeños
              proyectos.
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="portfolio-info">
              <h6 className="xbold black pb-2 pt-3">Nuestro plan de trabajo</h6>
              <p className="pb-3">
                En AG proveemos ideas, diseño y contenidos al negocio de
                nuestros clientes, con foco en productos y servicios digitales.
                Hoy más que nunca, trabajamos atentos a las demandas de nuestros
                clientes, en línea y comunicados de forma permanente.
              </p>
              <hr />
            </div>
          </div>
          <div className="col-md-6">
            <div className="portfolio-info">
              <h6 className="xbold black pb-2 pt-3">Hiperconectados</h6>
              <p>
                Todos los canales son útiles para el feedback y para mantener el
                liderazgo en los proyectos. En AG creemos en la mejora continua
                de nuestro equipo de diseñadores y desarrolladores, para tiempos
                de cambio desafiantes y llenos de oportunidades.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <h4 className="xbold black">
              Clientes e instituciones que confían.
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="portfolio-info">
              <p className="black pb-3">
                <Link to="/trabajos/smiles">
                  <u className="link-hover">Smiles Argentina</u>
                </Link>
                <br />
                <Link to="/trabajos/wilo">
                  <u className="link-hover">Wilo LATAM</u>
                </Link>
                <br />
                <Link to="/trabajos/SMC360">
                  <u className="link-hover">SMC360</u>
                </Link>
                <br />
                <Link to="/trabajos/ceramica-cregar">
                  <u className="link-hover">Cerámica Cregar</u>
                </Link>
                <br />
                <Link to="/trabajos/ceramica-industrial-avellaneda">
                  <u className="link-hover">Cerámica Industrial Avellaneda</u>
                </Link>
              </p>
              <hr />
            </div>
          </div>
          <div className="col-md-6">
            <div className="portfolio-info">
              <p className="black">
                Clarín
                <br />
                <Link to="/trabajos/unraf">
                  <u className="link-hover">Universidad Nacional de Rafaela</u>
                </Link>
                <br />
                Universidad de Palermo
                <br />
                Gráffica (España)
                <br />
                <Link to="/trabajos/midios">
                  <u className="link-hover">Midios</u>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <h4 className="xbold black">
              Un equipo adaptado a las demandas y necesidades de nuestro
              clientes.
            </h4>
          </div>
        </div>
        <br />
        <div className="row">
          {NosotrosData.map((integrante, key) => {
            return (
              <div className="col-md-6">
                <img
                  src={integrante.img1.default}
                  className="nosotros-img"
                  key={key}
                  alt={integrante.name}
                />
                <p className="mt-3">
                  — <span className="xbold black">{integrante.name}</span>
                </p>
                <p className="mb-0 black">
                  <b>{integrante.boldText}</b> {integrante.text}
                </p>
                <p className="black">
                  <i>{integrante.legend}</i>
                </p>
              </div>
            );
          })}
        </div>
        <div className="row mt-5">
          {ColaboradoresData.map((colaborador, key) => {
            return (
              <div className="col-md-3 mb-4">
                <p className="mt-2">
                  <span className="xbold black">{colaborador.name}</span>
                  <br />
                  <span>{colaborador.text}</span>
                </p>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default Portfolio;

import React from 'react';
import Portfolio from './Portfolio';
import Footer from './Footer';

export default function Nosotros() {
    return(
        <React.Fragment>
            <Portfolio />
            <Footer />
        </React.Fragment>
    )
}
import React from 'react';
import CIACarousel from './CIACarousel';
import Footer from '../Footer';
import portfolioCIA1 from '../../assets/img/portfolio-cia-1.jpg';
import portfolioCIA2 from '../../assets/img/portfolio-cia-2.jpg';
import portfolioCIA3 from '../../assets/img/portfolio-cia-3.jpg';
import portfolioCIA4 from '../../assets/img/portfolio-cia-4.jpg';

export default function SmilesItem() {
    return(
        <React.Fragment>
            <section id="portfolio">
                <div className="container">
                    <div className="col-md-6">
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <CIACarousel />
                        </div>
                        <div className="container col-md-11">
                            <div className="row mt-3">
                                <div className="col-md-4 padding-0 d-none d-sm-block">
                                </div>
                                <div className="col-md-8 padding-0 mt-5">
                                    <div>
                                        <div className="portfolio-info pl-3">
                                            <p>CERÀMICA INDUSTRIAL AVELLANEDA</p>
                                            <h4 className="xbold black">Materiales para el mundo</h4>
                                        </div>
                                        <br />
                                        <p className="pl-3">
                                            En AG ayudamos en la gestión de su comunicación
                                            digital e impresa a Cerámica Industrial Avellaneda,
                                            fábrica de materiales refractarios para las industrias
                                            siderúrgicas, petroquímicas metalúrgicas, cementeras,
                                            caleras, vidrio y aluminio.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={portfolioCIA1} alt="Imagen principal" className="img-fluid-2 mb-5 mt-5" />
                                </div>
                                    <div className="col-md-4 p-0 d-none d-sm-block">
                                    </div>
                                    <div className="col-md-8 p-0">
                                        <img src={portfolioCIA2} alt="Imagen principal" className="img-fluid-2 mt-4" />
                                    </div>
                                <div className="col-md-4 p-0 d-none d-sm-block">
                                </div>
                                <div className="col-md-8 p-0 mb-5 mt-4">
                                    <h4 className="pl-3 black xbold mt-4 pt-3">Lo que hacemos para Cerámica Industrial Avellaneda</h4>
                                        <p className="pl-3 black">
                                            → Ejecuciones creativas. <br/>
                                            → Desarrollo de sitio institucional <br/>
                                            → Mantenimiento de redes sociales. <br/>
                                            → Piezas impresas y POP. <br/>
                                            → Comunicaciones institucionales. <br/>
                                            → Apoyo visual y señalética para stands y puntos de venta. <br/>
                                            → Gestión y asistencia creativa. <br/>
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                            <img src={portfolioCIA3} className="mb-3 img-fluid-2 mb-5" alt="Porfolio Cregar"/>    
                    </div>
                </div>
                <div className="container">
                    <div className='container col-md-10'>
                        <div className='row'>
                            <img src={portfolioCIA4} alt="Imagen principal" className="img-fluid-2 mt-5" />
                        </div>
                    </div>
                </div>
            </section>
                    <Footer />
        </React.Fragment>
    )
}

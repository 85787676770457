import React from 'react';
import Footer from './Footer';
import Proyectos from './Proyectos';

export default function Trabajos() {
    return(
       <React.Fragment>
           <Proyectos/>
           <Footer/>
       </React.Fragment>
    )
}
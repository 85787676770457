export const NosotrosData = [
    {
        name: "Lucas López",
        img1: require("../assets/img/nosotros-1.jpg"),
        boldText: "Fundador y director de AG.",
        text: "Agencia especializada en branding, diseño y contenidos.",
        legend: "“En AG combinamos nuestra experiencia en diseño y comunicación orientados a las nuevas demandas del mercado, con una respuesta ágil para los negocios de hoy. Aportamos soluciones creativas a la estrategia de nuestros clientes.”"
    },
    // {
    //     name: "Candela Iglesias",
    //     img1: require("../assets/img/nosotros-2.jpg"),
    //     boldText: "Líder de proyectos.",
    //     text: "Contenido gráfico y animado para distintas marcas, creatividad de identidades visuales. Forma parte del equipo de la agencia desde fines de 2019.",
    //     legend: "“En AG, diseño y creación de contenidos digitales, además de adaptaciones para los distintos formatos de redes y pautas gráficas.”"
    // },
    {
        name: "Débora Jael Janavel",
        img1: require("../assets/img/nosotros-5.jpg"),
        boldText: "Diseñadora UX, creatividad y Design Thinking.",
        text: "Forma parte del equipo desde mediados de 2020.",
        legend: "“Mi objetivo está puesto en empatizar con clientes y usuarios, entender sus necesidades, idear estrategias y diseñar soluciones trabajando con equipos interdisciplinarios.”"
    },
    {
        name: "Kevin Hernández",
        img1: require("../assets/img/nosotros-3.jpg"),
        boldText: "Líder de proyectos.",
        text: "Soluciones creativas con foco en redes sociales y digital. Nacido en Venezuela, tras un paso laboral por Perú, forma parte del equipo desde mediados de 2019.",
        legend: " “Creamos y adaptamos piezas gráficas que establecen el marco de comunicación necesario para las demandas del cliente.”"
    },
    {
        name: "Florencia Pak",
        img1: require("../assets/img/nosotros-6.jpg"),
        boldText: "Diseñadora especializada en editorial y entornos digitales.",
        text: "Forma parte del equipo de AG desde fines de 2020.",
        legend: "“Proactividad, organización y responsabilidad son las herramientas personales para cualquier proyecto que desempeñe, atenta a cada etapa del trabajo.”"
    },
    {
        name: "Pablo Faro",
        img1: require("../assets/img/nosotros-4.jpg"),
        boldText: "Director audiovisual, diseño de motion graphics y efectos.",
        text: "Ayuda a AG desde mediados de 2019.",
        legend: "“Más y mejores proyectos, aportando desde las herramientas y tecnologías de pantalla para animación de piezas promocionales”"
    },
    {
        name: "Lucas Carino",
        img1: require("../assets/img/nosotros-7.jpg"),
        boldText: "Desarrollador web.",
        text: "Forma parte del equipo de AG desde fines de 2020.",
        legend: "“Mi objetivo es desarrollar mis capacidades en los distintos lenguajes de programación y lograr los mejores resultados en las prácticas y proyectos asignados.”"
    }
]

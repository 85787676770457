export const TrabajosData = [
        {
            name: "smiles",
            title: "smiles",
            subtitle: "Cultura de millas",
            caption1: "En AG aportamos ideas, diseño, contenidos, redacción, asistencia, desarrollo, programación, animaciones, estrategia, experiencia para la comunicación digital de Smiles Argentina. Un equipo multidisciplinario que  todos los días construye y consolida la Cultura Smiles",
            caption2: "Smiles requieren una amplia demanda de servicios, de acuerdo al volumen y exigencias del mercado, aportando creatividad operativa y soluciones que ayudan al desenvolvimento diario del negocio del turismo.",
            mainImg: require("../assets/img/smiles-gif.gif"),
            img1: require("../assets/img/img-trabajo-1.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "wilo",
            title: "wilo",
            subtitle: "Soluciones verdes",
            caption1: "La empresa alemana Wilo confía en AG para la totalidad de su comunicación para Argentina y LATAM, desde catálogos y material impreso, a su estrategia en redes sociales, pauta online y contenidos.",
            caption2: "Ejecuciones creativas para las redes sociales de Wilo, además de piezas impresas como avisos, catálogos y listas de precios, entre otras comunicaciones institucionales. Apoyo visual en stands, puntos de venta y flota de vehículos.",
            mainImg: require("../assets/img/img-trabajo-2.jpg"),
            img1: require("../assets/img/img-trabajo-2.jpg"),
            img2: require("../assets/img/img-trabajo-2.jpg"),
            img3: require("../assets/img/img-trabajo-2.jpg")
        },
        {
            name: "smc360",
            title: "smc360",
            subtitle: "Sponsorship y contenidos",
            caption1: "Diseñamos y gestionamos la identidad de marca, la comunicación y el ecosistema de redes sociales de la agencia de medios SMC360. Nuestro aporte en ideas, diseño, contenidos para una agencia de medios siempre innovadora.",
            caption2: "En AG creamos contenidos propios para las distintas necesidades de la agencia, activando visión y estrategia de marca y aportando valor de mercado. En la filosofía de SMC360, más y mejores historias orientadas a nuevas audiencias.",
            mainImg: require("../assets/img/img-trabajo-3.jpg"),
            img1: require("../assets/img/img-trabajo-1.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "ceramica-cregar",
            title: "cerámica cregar",
            subtitle: "Líder en refractarios",
            caption1: "Creamos y coordinamos el Departamento de Comunicaciones Cregar, donde gestionamos, junto a un equipo, la totalidad de la imagen de Cerámica Cregar, lider en cerámicos refractarios.",
            caption2: "De esta forma Cerámica Cregar logró su posicionamiento y liderazgo en el mercado de los ladrillos y refractarios. Una comunicación emitida diariamente por parte de AG, de competencia efectiva en el sector de la construcción",
            mainImg: require("../assets/img/cregar-gif.gif"),
            img1: require("../assets/img/img-trabajo-1.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "unraf",
            title: "universidad nacional de rafaela",
            subtitle: "Colección de ideas",
            caption1: "En AG ayudamos en la identidad visual y el desarrollo editorial de las colecciones Diseño e IDi (Investigación, Desarrollo e innovación) de la Universidad Nacional de Rafaela. ",
            caption2: "El Manual de Estilo de la Colección Diseño abarca las guías más importantes como el formato, la retícula, la tipografía y el sistema de cubiertas, la cromática, la estructura y base de contenidos, entre otros lineamientos editoriales.",
            mainImg: require("../assets/img/img-trabajo-5.jpg"),
            img1: require("../assets/img/img-trabajo-5.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "real-madrid",
            title: "real madrid",
            subtitle: "MKT deportivo",
            caption1: "Lanzada en mayo y junio de 2019 bajo el hashtag #UnaExperienciaParaToda LaVida, la activación creada para las clínicas de Fundación Real Madrid México incluyó más de 100 piezas gráficas de comunicación para redes sociales, pauta publicitaria, y señalética para campos de deportes.",
            caption2: "Siguiendo normas gráficas de marca del club, la potencia y prestigio del Real Madrid como firma, sin duda, consolidan el conjunto de piezas gráfcas creadas para la activación, que apelan a un tono local con espíritu global, con la educación y práctica del fútbol infantil y juvenil como eje.",
            mainImg: require("../assets/img/img-trabajo-6.jpg"),
            img1: require("../assets/img/img-trabajo-6.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "cerini",
            title: "cerini",
            subtitle: "Para hombres",
            caption1: "En AG ayudamos a Claudio Cerini en la identidad de marca y piezas gráficas de su nuevo salón Club De Caballeros, inaugurado en Paseo Alcorta Shopping. Diseñamos la marca gráfica, manual de aplicación y avisos para medios",
            caption2: "Smiles requieren una amplia demanda de servicios, de acuerdo al volumen y exigencias del mercado, aportando creatividad operativa y soluciones que ayudan al desenvolvimento diario del negocio del turismo.",
            mainImg: require("../assets/img/img-trabajo-7.jpg"),
            img1: require("../assets/img/img-trabajo-7.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "nexx",
            title: "nexx",
            subtitle: "Humanizando datos",
            caption1: "Creamos NEXX, la agencia de estrategia digital 360° que acompaña a AG en su estructura de servicios. Un equipo interdisciplinario que cree en el valor de los datos y en medir para mejorar campañas de posicionamiento y performances.",
            caption2: "NEXX es una consultoría de estrategia digital orientada 100% a performance: social listening, comunicación segmentada (orgánica y pautada), UX+UI y CRO (Conversion Rate Optimization) y campañas, entre otros servicios digitales.",
            mainImg: require("../assets/img/img-trabajo-8.jpg"),
            img1: require("../assets/img/img-trabajo-8.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "midios",
            title: "midios",
            subtitle: "Planificación y estrategia",
            caption1: "Creamos NEXX, la agencia de estrategia digital 360° que acompaña a AG en su estructura de servicios. Un equipo interdisciplinario que cree en el valor de los datos y en medir para mejorar campañas de posicionamiento y performances.",
            caption2: "NEXX es una consultoría de estrategia digital orientada 100% a performance: social listening, comunicación segmentada (orgánica y pautada), UX+UI y CRO (Conversion Rate Optimization) y campañas, entre otros servicios digitales.",
            mainImg: require("../assets/img/img-trabajo-9.png"),
            img1: require("../assets/img/img-trabajo-8.jpg"),
            img2: require("../assets/img/img-trabajo-1.jpg"),
            img3: require("../assets/img/img-trabajo-1.jpg"),
        },
        {
            name: "ceramica-industrial-avellaneda",
            title: "cerámica industrial avellaneda",
            subtitle: "Materiales para el mundo",
            caption1: "Creamos NEXX, la agencia de estrategia digital 360° que acompaña a AG en su estructura de servicios. Un equipo interdisciplinario que cree en el valor de los datos y en medir para mejorar campañas de posicionamiento y performances.",
            caption2: "NEXX es una consultoría de estrategia digital orientada 100% a performance: social listening, comunicación segmentada (orgánica y pautada), UX+UI y CRO (Conversion Rate Optimization) y campañas, entre otros servicios digitales.",
            mainImg: require("../assets/img/img-trabajo-10.jpg"),
            img1: require("../assets/img/img-trabajo-10.jpg"),
            img2: require("../assets/img/img-trabajo-10.jpg"),
            img3: require("../assets/img/img-trabajo-10.jpg"),
        },
    ]
